import { ShowcaseLogo } from "@/components/icons";
import { NavigationDashboardMenuContainer } from "@/components/navigation/NavigationDashboardMenuContainer";
import AppContext from "@/context/AppContext";
import { futuraNextCondensedExtraBold } from "@/styles/fonts";
import theme from "@/styles/theme";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ClickAwayListener } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import Toolbar from "@mui/material/Toolbar";
import Link from "next/link";
import React, { useContext, useState } from "react";
import { DashboardMenu } from "./DashboardMenu";
import { NavigationDesktopMenu } from "./NavigationDesktopMenu";
import { NavigationDesktopMenuItem } from "./NavigationDesktopMenuItem";
import { NavigationLogin } from "./NavigationLogin";

export const NavigationDesktop = () => {
  const { isAuthenticated } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);

  // Disabled redirect, forces redirect on every refresh
  /*
  useEffect(() => {
    if (appContext.isAuthenticated) {
      //Router.push("/secure/"); // redirect if you're already logged in
    }
  }, [appContext.isAuthenticated]);
  */

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const navigation = {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },

    "& .navigation": {
      "& .MuiToolbar-root": {
        height: "82px",
      },

      "&--logo": {
        transform: "translateY(.625rem)",
        transition: "transform 100ms ease-out",
        "&:hover": {
          transform: "translateY(.25rem)",
        },
        "&:active": {
          opacity: ".85",
        },
      },

      "&--links": {
        "& .MuiButton-root": {
          p: 0,
          fontSize: "1.625rem",
          fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.secondary.main,
          },
          "&:active": {
            opacity: ".85",
          },
          "&__is-active": {
            color: theme.palette.secondary.main,
          },
        },
        "& .MuiPaper-root": {
          backgroundColor: theme.palette.secondary.main,
          minWidth: "10rem",
          padding: "1rem 2rem",
          "& .MuiList-root": {
            "& .MuiMenuItem-root": {
              padding: ".125rem 0",
              fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
              textTransform: "uppercase",
              color: theme.palette.common.white,
              backgroundColor: "transparent",
              "&:hover": {
                color: theme.palette.primary.main,
                backgroundColor: "transparent",
              },
              "&:active": {
                backgroundColor: "transparent",
              },
            },
            "& > .MuiMenuItem-root": {
              fontSize: "1.625rem",
            },
          },
        },
      },

      "&--submenu": {
        "& > .MuiMenuItem-root": {
          fontSize: "1.375rem",
          marginLeft: "1rem",
        },
      },

      "&--dashboardmenu": {
        "& .MuiPaper-root": {
          mt: ".75rem",
        },
      },
    },
  };

  return (
    <>
      <Box sx={navigation} className="navigation">
        <AppBar position="fixed" color="primary" elevation={0}>
          <Toolbar disableGutters={true}>
            <Container maxWidth="xl">
              <Grid container justifyContent="center">
                <Grid item sm={10}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Grid
                        container
                        spacing={5}
                        alignItems="center"
                        className="navigation--links"
                      >
                        <Grid item xs="auto">
                          <Link href="/" passHref legacyBehavior>
                            <Button disableRipple className="navigation--logo">
                              <ShowcaseLogo />
                            </Button>
                          </Link>
                        </Grid>
                        <NavigationDesktopMenu title="Musik">
                          <NavigationDesktopMenuItem
                            href="/musik/suchen"
                            label="Musik suchen"
                          />
                          <NavigationDesktopMenuItem
                            href="/musik/entdecken"
                            label="Musik entdecken"
                          />
                          <NavigationDesktopMenuItem
                            href="/musik"
                            label="Musik Index"
                          />
                          <NavigationDesktopMenuItem
                            href="/musik/events"
                            label="Musik Kalender"
                          />
                        </NavigationDesktopMenu>
                        <NavigationDesktopMenu title="Netzwerk">
                          <NavigationDesktopMenuItem
                            href="/netzwerk/showcase-map"
                            label="Showcase Map"
                          />
                          <NavigationDesktopMenuItem
                            href="/netzwerk/ueber-uns"
                            label="Über uns"
                          />

                          <Box className="navigation--submenu">
                            <NavigationDesktopMenuItem
                              href="/netzwerk/ueber-uns/createmusic"
                              label="create music NRW"
                            />
                            <NavigationDesktopMenuItem
                              href="/netzwerk/ueber-uns/newheimatsounds"
                              label="New. Heimat. Sounds."
                            />
                          </Box>
                          <NavigationDesktopMenuItem
                            href="/netzwerk/partner"
                            label="Partner"
                          />
                        </NavigationDesktopMenu>
                        <NavigationDesktopMenu title="Förderung">
                          <NavigationDesktopMenuItem
                            href="/foerderung/foerderfinder"
                            label="Förderfinder"
                          />
                          <NavigationDesktopMenuItem
                            href="/foerderung/workshops"
                            label="Workshops"
                          />
                          <NavigationDesktopMenuItem
                            href="/foerderung/wissenswertes"
                            label="Wissenswertes"
                          />
                        </NavigationDesktopMenu>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs="auto"
                      className={`"navigation--loginmenu ${
                        isAuthenticated
                          ? "navigation--dashboardmenu"
                          : "navigation--loginmenu"
                      }`}
                    >
                      {isAuthenticated ? (
                        <>
                          <NavigationDashboardMenuContainer>
                            <DashboardMenu />
                          </NavigationDashboardMenuContainer>
                        </>
                      ) : (
                        <>
                          <ClickAwayListener onClickAway={handleClose}>
                            <Box sx={{ position: "relative" }}>
                              <Button
                                variant="outlined-white"
                                startIcon={<ExitToAppIcon />}
                                ref={anchorRef}
                                id="composition-button"
                                aria-controls={
                                  open ? "composition-menu" : undefined
                                }
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                              >
                                Anmelden
                              </Button>
                              <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-end"
                                transition
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin:
                                        placement === "bottom-end"
                                          ? "right top"
                                          : "right bottom",
                                    }}
                                  >
                                    <Box>
                                      <NavigationLogin
                                        setOpen={setOpen}
                                        open={open}
                                      />
                                    </Box>
                                  </Grow>
                                )}
                              </Popper>
                            </Box>
                          </ClickAwayListener>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </Box>
    </>
  );
};
